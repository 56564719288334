import { useLocation, useNavigation } from '@remix-run/react'
import { createRef, lazy, useEffect, useRef } from 'react'

import type { LoadingBarRef } from 'react-top-loading-bar'
import isBrowser from '~/core/generic/is-browser'
import ClientOnly from '~/core/ui/ClientOnly'

const TopLoadingBar = lazy(() => import('react-top-loading-bar'))

function AppRouteLoadingIndicator() {
  return (
    <ClientOnly>
      <LoadingBar />
    </ClientOnly>
  )
}

function LoadingBar() {
  const ref = createRef<LoadingBarRef>()
  const runningRef = useRef(false)
  const navigation = useNavigation()
  const location = useLocation()
  const state = navigation.state
  const prevPathname = useRef(window.location.pathname);

  useEffect(() => {
    const isIdle = state === 'idle'
    const isRouteLoading = state === 'loading'

    const isActualNavigation = 
      navigation.state === "loading" && 
      navigation.location && 
      navigation.location.pathname !== prevPathname.current;

    if (isActualNavigation) {
      prevPathname.current = navigation.location.pathname
      if (isRouteLoading) {
        ref.current?.continuousStart()
        runningRef.current = true
      }

    }

    if (isIdle && runningRef.current) {
      ref.current?.complete()
      runningRef.current = false
    }
  }, [ref, state])

  // HACK to stop showing loading for internal fetcher
  if (location.pathname.includes('/topic/')) {
    return null
  }

  if (!isBrowser()) {
    return null
  }

  return (
    <TopLoadingBar
      height={4}
      waitingTime={200}
      shadow
      className="bg-primary"
      color=""
      ref={ref}
    />
  )
}

export default AppRouteLoadingIndicator
