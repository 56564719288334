import './styles/index.css';

import type { LoaderFunctionArgs } from '@remix-run/server-runtime';
import { json } from '@remix-run/node';
import { withSentry } from '@sentry/remix';

import {
  Form,
  Links,
  Outlet,
  Meta as RemixMeta,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useLoaderData,
  useLocation,
  useMatches,
} from '@remix-run/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import classNames, { clsx } from 'clsx';

import { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import i18nNext from './i18n/i18n.server';
import * as gtag from './utils/gtags.client';
import Button from './core/ui/Button';
import Head from '~/core/ui/Head';
import { parseThemeCookie } from '~/lib/server/cookies/theme.cookie';
import AppRouteLoadingIndicator from '~/components/AppRouteLoadingIndicator';
import RootErrorBoundary from '~/components/RootErrorBoundary';
import configuration from '~/configuration';
import { cookieConsent } from '~/lib/cookie.server';
import { ThemeProvider } from './core/contexts/theme-context';
import { Config } from 'sst/node/config';

export function meta() {
  return [{ title: configuration.site.name }];
}

export async function loader({ request }: LoaderFunctionArgs) {
  const theme = await parseThemeCookie(request);
  const locale = await i18nNext.getLocale(request);
  const cookieHeader = request.headers.get('Cookie');
  const cookie = (await cookieConsent.parse(cookieHeader)) || {};
  const gaTrackingId =
    Config.STAGE === 'prod'
      ? 'G-TXD15PSF4T'
      : Config.STAGE === 'dev'
        ? 'G-35RPM9VG2D'
        : null;
  return json({
    gaTrackingId,
    locale,
    theme,
    track: cookie.gdprConsent,
    ENV: getBrowserEnvironment(),
  });
}
export { shouldRevalidate } from './routes/_app.transcribe.$id._index/route';
export function Analytics() {
  const [posthogLoaded, setPosthogLoaded] = useState(false)
  const analyticsFetcher = useFetcher()
  const data = useLoaderData<typeof loader>()
  const location = useLocation()

  // Add this state to track local acceptance status
  const [hasAccepted, setHasAccepted] = useState(false)

  useEffect(() => {
    if ((data.track || hasAccepted) && data.ENV.POSTHOG_API_KEY) {
      posthog.init(data.ENV.POSTHOG_API_KEY, {
        api_host: 'https://eu.i.posthog.com',
        loaded: () => {
          setPosthogLoaded(true);
        },
      });
    }
  }, [location, data.track, hasAccepted, data.ENV.POSTHOG_API_KEY])

  useEffect(() => {
    if (posthogLoaded) {
      posthog.capture('$pageview');
    }
  }, [posthogLoaded, location.pathname]);

  // Add this effect to handle fetcher state
  useEffect(() => {
    if (analyticsFetcher.state === 'loading' && analyticsFetcher.formData?.get('accept-gdpr') === 'true') {
      setHasAccepted(true)
    }
  }, [analyticsFetcher.state, analyticsFetcher.formData])

  return (
    <div
      id="cookies-simple-with-icon-and-dismiss-button"
      className={clsx(
        'fixed bottom-0 right-0 z-[60] mx-auto w-full p-6 sm:max-w-xl',
        {
          // Update this to use both server data and local state
          'pointer-events-none opacity-0': data.track || hasAccepted,
          'pointer-events-auto opacity-100': !data.track && !hasAccepted,
        },
      )}
    >
      <analyticsFetcher.Form
        method="post"
        action="/action/enable-analytics"
        className="rounded-xl border border-gray-200 bg-slate-100 p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800"
      >
        <div className="grid gap-y-3 sm:flex sm:items-center sm:gap-x-5 sm:gap-y-0">
          <div className="sm:max-w-sm">
            <h2 className="text-gray-500">
              <span className="font-semibold text-gray-800 dark:text-gray-200">
                We use cookies
              </span>{' '}
              to analyze our traffic and create a smooth user experience.
            </h2>
          </div>
          <Button
            name="accept-gdpr"
            value="true"
            type="submit"
            className="inline-flex items-center justify-center gap-2 "
          >
            Accept
          </Button>
        </div>
      </analyticsFetcher.Form>
    </div>
  );
}

// Add type for the match object
interface RouteMatch {
  id: string;
  // Add other properties if needed
}

function App() {
  const data = useLoaderData<typeof loader>();
  // Add proper typing for matches
  const matches = useMatches() as RouteMatch[];
  const isAppRoute = matches.some((match) => match.id === 'routes/_app');
  // If it's an app route (logged in) => Use the theme from data (cookie)
  // If it's NOT an app route (front page) => Light theme (false)
  const isDarkTheme = isAppRoute ? data.theme === 'dark' : false;
  const location = useLocation();
  const { gaTrackingId } = useLoaderData<typeof loader>();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
  );

  const [themeClass, setThemeClass] = useState(
    classNames({
      dark: isDarkTheme,
    }),
  );

  useEffect(() => {
    setThemeClass(
      classNames({
        dark: isDarkTheme,
      }),
    );
  }, [isDarkTheme]);

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <html lang={data.locale} className={themeClass}>
      <head>
        <RemixMeta />
        <Links />
        <Head />

        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
      </head>
      <body className="h-full">
        {process.env.NODE_ENV === 'development' || !gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <QueryClientProvider client={queryClient}>
          <ThemeProvider initialTheme={data.theme || undefined}>
            <Outlet />
          </ThemeProvider>
        </QueryClientProvider>
        <AppRouteLoadingIndicator />
        <Analytics />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = RootErrorBoundary;

function getBrowserEnvironment() {
  const env = process.env;

  return {
    IS_CI: env.IS_CI,
    SITE_URL: env.SITE_URL,
    DEFAULT_LOCALE: env.DEFAULT_LOCALE,
    NODE_ENV: env.NODE_ENV,
    SENTRY_DSN: env.SENTRY_DSN,
    SUPABASE_URL: env.SUPABASE_URL,
    SUPABASE_ANON_KEY: env.SUPABASE_ANON_KEY,
    ENVIRONMENT: env.ENVIRONMENT,
    STRIPE_PUBLISHABLE_KEY: env.STRIPE_PUBLISHABLE_KEY,
    ENABLE_ACCOUNT_DELETION: env.ENABLE_ACCOUNT_DELETION,
    ENABLE_ORGANIZATION_DELETION: env.ENABLE_ORGANIZATION_DELETION,
    STAGE: env.STAGE,
    POSTHOG_API_KEY: env.POSTHOG_API_KEY,
    WEBSOCKET_API_ENDPOINT: env.WEBSOCKET_API_ENDPOINT,
    API_ENDPOINT: env.API_ENDPOINT,
    DASHBOARD_ELEMENT_URL: env.DASHBOARD_ELEMENT_URL,
  };
}

export default withSentry(App);
